<template>
  <div class="flex flex-col items-center gap-10 pt-28 xs:pt-36 pb-20 px-8 lg:px-28 2xl:px-32 min-h-screen">
    <p class="text-4xl sm:text-7xl md:text-8xl xl:text-9xl font-semibold text-center animated-block-y">Un equipo con <br> gran <span class="italic">impacto</span> y <span class="italic">ambición</span></p>
    <div class="grid gap-10 grid-cols-1 md:grid-cols-2 2xl:grid-cols-4 animated-block-y">
        <TeamMember :photo="manuel" name="Manuel Pérez Bejarano" role="Co-Founder y CEO" />
        <TeamMember :photo="gonzalo" name="Gonzalo Rodríguez Terrón" role="Co-Founder y Director Creativo" />
        <TeamMember :photo="ruben" name="Rubén Gómez Morales" role="Co-Founder y CTO" />
        <TeamMember :photo="marina" name="Marina Entrambasaguas Real" role="Directora de Marketing" />
    </div>
    <div class="flex flex-col gap-2 pt-10 text-center">
        <p class="font-semibold text-2xl underline animated-block-y">Juntos por un propósito</p>
        <p class="leading-normal font-medium text-xl animated-block-y">
            Detrás de TuParty hay un equipo apasionado que busca transformar cómo se vive la música en las discotecas, creando experiencias únicas cada noche. Con experiencia en tecnología, creatividad y ventas, trabajamos juntos para ofrecer una solución que beneficia tanto a DJs como a discotecas. Nuestra misión es clara: conectar al público con los DJs y hacer que cada canción sea un momento especial.
        </p>
    </div>
  </div>
</template>

<script setup>
import TeamMember from '@/components/About/TeamMember.vue';
import manuel from '@/assets/images/manuel.png';
import gonzalo from '@/assets/images/gonzalo.png';
// import samuel from '@/assets/images/samuel.png';
import ruben from '@/assets/images/ruben.png';
import marina from '@/assets/images/marina.png';
import { useAnimationObserver } from '@/composables/useAnimationObserver';

useAnimationObserver();
</script>